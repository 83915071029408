<template>
    <div class="form-main">
        <div v-if="label" class="form-main-l">{{label}}</div>
        <div class="form-line"></div>
        <div style="width: 70%;">
            <el-input v-model="val" class="input-main-v" :type="type" rows="7" @change="doChange" @input="doInput"></el-input>
        </div>
        <div v-if="suffix" class="input-main-r">{{suffix}}</div>
    </div>
</template>

<script>
    export default {
        name: "XyInput",
        props:{
            label:{
                type:String,
                required:false
            },
            value:{
                required:false
            },
            suffix:{
                required:false
            },
            type: {
                required: false,
                default: 'text'
            }
        },
        data() {
            return {
                val: this.value,
            }
        },
        watch: {
            value(val) {
                this.val = val;
            },
            val(val) {
                this.$emit('update:value', val)
            }
        },
        methods:{
            doChange(e) {
                this.$emit('change', e)
            },
            doInput(e) {
                this.$emit('input', e)
            }
        }
    }
</script>

<style scoped>
    .input-main-v >>> .el-input__inner{
        background-color: transparent;
        border: unset;
        height: 30px;
        caret-color: white;
        color: white;
        font-size: 12px;
        padding: unset;
    }
    .input-main-v >>> .el-textarea__inner{
        background-color: transparent;
        border: unset;
        caret-color: white;
        color: white;
        font-size: 12px;
        padding: unset;
    }
    .input-main-r {
        margin-right: 5px;
        white-space: nowrap;
    }
</style>
