export default {
  // https://juejin.im/entry/5b838d956fb9a01a0e075b2d
  randomString() {
    const length = 32
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = Math.random()
      .toString(36)
      .slice(-8)
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)]
    return result
  },
  // https://github.com/tjcafferkey/stringinject
  stringInject(str, data) {
    if (typeof str === 'string' && Array.isArray(data)) {
      return str.replace(/({\d})/g, function(i) {
        return data[i.replace(/{/, '').replace(/}/, '')]
      })
    } else if (typeof str === 'string' && data instanceof Object) {
      if (Object.keys(data).length === 0) {
        return str
      }

      for (const key in data) {
        return str.replace(/({([^}]+)})/g, function(i) {
          const key = i.replace(/{/, '').replace(/}/, '')
          if (!data[key]) {
            return i
          }

          return data[key]
        })
      }
    } else if (
      (typeof str === 'string' && Array.isArray(data) === false) ||
      (typeof str === 'string' && data instanceof Object === false)
    ) {
      return str
    } else {
      return false
    }
  },
  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  },
  isEmpty(str) {
    return !str || str.trim() == ''
  },
  base64ConvertFile (urlData, filename) { // 64转file
    if (typeof urlData != 'string') {
      console.error("urlData不是字符串")
      return;
    }
    var arr = urlData.split(',')
    var type = arr[0].match(/:(.*?);/)[1]
    var fileExt = type.split('/')[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + '.' + fileExt, {
      type: type
    });
  },
  replaceWxImage(html) {
    if (html) {
      const host = process.env.VUE_APP_PROTOCOL + '://' + process.env.VUE_APP_HOST + ':' + process.env.VUE_APP_PORT
      html = html.replaceAll('https://mmbiz.qpic.cn/', host + '/cms-support/img/')
      // html = html.replaceAll(/width: \d*px/gi, 'width: 100%')
    }
    return html
  },
  //判断是否为正整数
  isNum(str) {
    return /^\d+$/.test(str)
  },
  isNumber(str) {
    return !isNaN(str)
  }
}
