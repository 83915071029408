<template>
    <div class="form-main">
        <div class="form-main-l">{{label}}</div>
        <div class="form-line"></div>
        <div class="single-select-v">
            <el-select v-model="val" placeholder="请选择" class="single-select-s" size="mini">
                <el-option
                        v-for="item in options"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    export default {
        name: "XySingleSelect",
        props:{
            label:{
                type:String,
                required:true
            },
            value:{
                required:false
            },
            options:{

            }
        },
        data() {
            return {
                val: this.value,
            }
        },
        watch: {
            value(val) {
                this.val = val;
            },
            val(val) {
                this.$emit('update:value', val)
            }
        }
    }
</script>

<style scoped>
    .single-select-v {
        width: calc(100% - 50px);
    }
    .single-select-s {
        width: 100%;
    }
.single-select-s >>> .el-input__inner{
    background-color: transparent;
    border: unset;
    height: 30px;
    caret-color: white;
    color: white;
    font-size: 12px;
    padding: unset;
    width: 100%;
}
</style>
