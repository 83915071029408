<template>
    <div class="result-main">
        <div>病理结果</div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ResultImageView"
    }
</script>

<style scoped>
.result-main{
    /*width: 100%;*/
    height: 100%;
    background-color: black;
    margin-top: 4px;
}
</style>
