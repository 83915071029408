<template>
    <div>
        <XyHeader title="湘雅AI辅助诊断录入系统"></XyHeader>
        <div v-if="mark" class="detail-main">
            <div class="detail-left">
                <MedicalImageView :pictures="mark.pictures" class="detail-left-up"></MedicalImageView>
                <ResultImageView class="detail-left-down">
                    <div>
                        <!--                        <XyUpload label="" :value.sync="mark.attachments"></XyUpload>-->
                    </div>
                </ResultImageView>
            </div>
            <div class="detail-right">
                <div>
                    <XyLabel label="病历" :text="mark.no"></XyLabel>
                </div>
                <!--                <div>{{mark.date}}</div>-->
                <!--                <div>{{mark.name}}</div>-->
                <div class="one-line">
                    <XyInput class="one-line-cell" label="年龄" :value.sync="mark.age"></XyInput>
                    <XySingleSelect class="one-line-cell" label="性别" :value.sync="mark.gender"
                                    :options="genderOptions"></XySingleSelect>
                </div>
                <div>
                    <XyMultiSelect label="首发症状" :value.sync="mark.sfzz" :options="sfzzOptions"></XyMultiSelect>
                </div>
                <div>
                    <XyMultiSelect label="肿瘤部位" :value.sync="mark.zlbw" :options="zlbwOptions"></XyMultiSelect>
                </div>
                <div>
                    <XySingleSelect label="肿瘤边界" :value.sync="mark.zlbj" :options="zlbjOptions"></XySingleSelect>
                </div>
                <div>
                    <XyInput label="侵袭脑叶数量" :value.sync="mark.qxnysl" suffix="个"></XyInput>
                </div>
                <div class="one-line">
                    <XyInput :value.sync="mark.zldx.length" @input="calVolume"></XyInput>
                    <div class="zldx-seperate">*</div>
                    <XyInput :value.sync="mark.zldx.width" @input="calVolume"></XyInput>
                    <div class="zldx-seperate">*</div>
                    <XyInput :value.sync="mark.zldx.height" @input="calVolume"></XyInput>
                    <div class="zldx-suffix">（肿瘤长*宽*高）</div>
                </div>
                <div>
                    <XyInput label="肿瘤体积" :value.sync="mark.zldx.volume" suffix="立方毫米"></XyInput>
                </div>
                <div>
                    <XySingleSelect label="囊实性" :value.sync="mark.nsx" :options="nsxOptions"></XySingleSelect>
                </div>
                <div>
                    <XySingleSelect label="钙化" :value.sync="mark.gh" :options="tfOptions"></XySingleSelect>
                </div>
                <div>
                    <XySingleSelect label="出血" :value.sync="mark.cx" :options="tfOptions"></XySingleSelect>
                </div>
                <div>
                    <XyInput label="水肿指数" :value.sync="mark.szzs"></XyInput>
                </div>
                <div>
                    <XySingleSelect label="复发胶质瘤" :value.sync="mark.ffjzl" :options="tfOptions"></XySingleSelect>
                </div>
                <div style="margin-bottom: 100px">
                    <!--                    <XySingleSelect label="病理结果" :value.sync="mark.bljg" :options="bljgOptions"></XySingleSelect>-->
                    <XyInput label="病理结果" :value.sync="mark.bljg" type="textarea"></XyInput>
                </div>
                <div class="action-div">
                    <el-button class="action-div-btn action-div-back" @click="goBack">返回</el-button>
                    <el-button class="action-div-btn action-div-save" @click="save">保存</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StringUtil from "../../common/utils/StringUtil";
import XyHeader from "../common/XyHeader";
import MedicalImageView from "./components/image-view/MedicalImageView";
import ResultImageView from "./components/image-view/ResultImageView";
import XyLabel from "./components/form/XyLabel";
import XyInput from "./components/form/XyInput";
import XySingleSelect from "./components/form/XySingleSelect";
import XyMultiSelect from "./components/form/XyMultiSelect";
// import XyUpload from "@/components/mark/components/form/XyUpload";

export default {
    name: "MarkEdit",
    components: {
        // XyUpload,
        XyMultiSelect, XySingleSelect, XyInput, XyLabel, ResultImageView, MedicalImageView, XyHeader
    },
    data() {
        return {
            mark: undefined,
            genderOptions: [
                {label: '男', value: 1},
                {label: '女', value: 2}
            ],
            sfzzOptions: [
                '癫痫',
                '颅高压症状',
                '神经功能障碍',
                '体检'
            ],
            zlbwOptions: [
                '脑叶',
                '脑室',
                '脑干',
                '小脑',
                '丘脑',
                '胼胝体'
            ],
            zlbjOptions: [
                {label: '清晰', value: '清晰'},
                {label: '模糊', value: '模糊'}
            ],
            nsxOptions: [
                {label: '囊性', value: '囊性'},
                {label: '囊实性', value: '囊实性'},
                {label: '实性', value: '实性'}
            ],
            bljgOptions: [
                {label: '切片结果', value: '切片结果'},
                {label: '分子分型', value: '分子分型'}
            ],
            tfOptions: [{label: '是', value: true}, {label: '否', value: false}],
            testImgUrls: [],
            testImgUrl: undefined,
            isShow: true,
            nowIndex: 0,
            nowIndex2: 0
        }
    },
    created() {
        const markId = this.$route.query.id
        this.fetchData(markId)
    },
    methods: {
        fetchData(markId) {
            this.$httpUtil.get(this.$urlConstant.xiangya.getUpdate + markId, (res) => {
                this.mark = res.data
                this.initImages()
            }, this)
        },
        initImages() {
            for (const picture of this.mark.pictures) {
                for (const pictureDir of picture.dirs) {
                    for (const url of pictureDir.urls) {
                        this.testImgUrls.push(url)
                    }
                }
            }
        },
        save() {
            if (this.mark.age && !StringUtil.isNum(this.mark.age)) {
                this.$message.error('年龄必须是数字')
                return
            }
            if (this.mark.qxnysl && !StringUtil.isNum(this.mark.qxnysl)) {
                this.$message.error('侵袭脑叶数量必须是数字')
                return
            }
            if (this.mark.zldx.volume && !StringUtil.isNumber(this.mark.zldx.volume)) {
                this.$message.error('体积必须是数字')
                return
            }
            if (this.mark.zldx.length && !StringUtil.isNumber(this.mark.zldx.length)) {
                this.$message.error('长必须是数字')
                return
            }
            if (this.mark.zldx.width && !StringUtil.isNumber(this.mark.zldx.width)) {
                this.$message.error('宽必须是数字')
                return
            }
            if (this.mark.zldx.height && !StringUtil.isNumber(this.mark.zldx.height)) {
                this.$message.error('高必须是数字')
                return
            }
            if (this.mark.szzs && !StringUtil.isNumber(this.mark.szzs)) {
                this.$message.error('水肿指数必须是数字')
                return
            }
            this.$httpUtil.put(this.$urlConstant.xiangya.getUpdate + this.mark.id, this.mark, (res) => {
                this.mark = res.data
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                setTimeout(() => {
                    this.goBack()
                }, 1000)
            }, this)
        },
        goBack() {
            this.$router.back()
        },
        calVolume() {
            setTimeout(()=>{
                if (StringUtil.isNumber(this.mark.zldx.length) &&
                    StringUtil.isNumber(this.mark.zldx.width) &&
                    StringUtil.isNumber(this.mark.zldx.height)){
                    this.mark.zldx.volume = (this.mark.zldx.length * this.mark.zldx.width * this.mark.zldx.height * 3.1415926/6).toFixed(2)
                }
            },100)

        }
    }
}
</script>

<style scoped>
.detail-main {
    display: flex;
    flex-direction: row;
    background-color: #272727;
    padding: 10px 0 0 18px;
}

.detail-left {
    width: 72%;
    height: calc(100vh - 50px);
    overflow: auto;
}

.detail-right {
    width: 28%;
    height: calc(100vh - 70px);
    overflow: auto;
    background-color: #000000;
    padding: 10px 20px;
}

.detail-left-up {
    height: calc(58% - 10px);
}

.detail-left-down {
    height: 42%;
}

.detail-right div {
    margin-bottom: 20px;
}

.one-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
}

.one-line-cell {
    width: calc(50% - 10px);
}

.zldx-seperate {
    font-family: SourceHanSansCN-Regular;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 1.49px;
    text-align: center;
    line-height: 35px;
    padding: 2px;
}

.zldx-suffix {
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0.89px;
    text-align: center;
    white-space: nowrap;
    line-height: 30px;
}

.action-div {
    position: fixed;
    bottom: 0px;
    background-color: black;
    width: 26%;
    height: 70px;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.action-div-btn {
    max-width: 124px;
    height: 30px;
    width: 45%;
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    letter-spacing: 0.69px;
    text-align: center;
    line-height: 5px;
    margin-top: 20px;
}

.action-div-back {
    background: #000000;
    border: 1px solid #54B8C7;
    border-radius: 5px;
    color: #54B8C7;

}

.action-div-save {
    background: #54B8C7;
    border-radius: 5px;
    border: unset;
    color: #FFFFFF;
}
</style>
