<template>
    <div v-if="imgUrls && imgUrls.length > 0" :class="imgClass" ref="imgDiv" id="imgDiv" @mousewheel="handleScroll">
        <img :src="getFullUrl(this.imgUrls[nowIndex])">
    </div>
</template>

<script>
    export default {
        name: "ScrollImageView",
        props: {
            imgUrls: {
                type: Array,
                required: true
            },
            imgClass:{
                type: String,
                required: false,
                default: 'img-div'
            }
        },
        data() {
            return {
                nowIndex:0
            }
        },

        methods:{
            handleScroll (e) {
                // const direction = e.deltaY>0?'down':'up' //该语句可以用来判断滚轮是向上滑动还是向下
                // console.log(`direction:${direction}`)
                if (e.deltaY > 0) {
                    if(this.nowIndex < this.imgUrls.length-1){
                        this.nowIndex ++
                    }else {
                        this.nowIndex = 0
                    }
                }else {
                    if(this.nowIndex > 0){
                        this.nowIndex --
                    }else {
                        this.nowIndex = this.imgUrls.length-1
                    }
                }
                e.preventDefault()
            },
            getFullUrl(imageUrl) {
                if (imageUrl.startsWith("http"))
                    return imageUrl
                const baseUrl = process.env.VUE_APP_PROTOCOL + '://'+process.env.VUE_APP_HOST+':'+process.env.VUE_APP_PORT
                return baseUrl + '/xiangya/orthanc/img/instances/' + imageUrl + '/preview'
            }
        }
    }
</script>

<style scoped>
    .img-div{
        width: 100%;
        height: 280px;
    }
    .img-div img{
        width: 100%;
        height: 280px;
        object-fit: contain;
    }
</style>
