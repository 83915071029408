<template>
    <div class="form-main">
        <div class="form-main-l">{{label}}</div>
        <div class="form-line"></div>
        <div class="label-main-t">{{text}}</div>
    </div>
</template>

<script>
    export default {
        name: "XyLabel",
        props:{
            label:{
                type:String,
                required:true
            },
            text:{
                type:String,
                required:true
            }
        }
    }
</script>

<style scoped>

</style>
