<template>
    <div class="coordinate-image-view">
        <div>
            <el-select v-model="selectedUrls" class="coordinate-select" size="mini">
                <el-option v-for="pictureDir in picture.dirs" :key="pictureDir.seriesId"
                           :label="pictureDir.dir"
                           :value="pictureDir.urls"></el-option>
            </el-select>
        </div>
        <DirImageView :img-urls="selectedUrls"></DirImageView>
    </div>
</template>

<script>
    import DirImageView from "./DirImageView";

    export default {
        name: "CoordinateImageView",
        components: {
            DirImageView
        },
        props: {
            picture: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                selectedDir: '',
                selectedUrls: []
            }
        },
        mounted() {
            if (this.picture.dirs && this.picture.dirs.length > 0) {
                this.selectedUrls = this.picture.dirs[0].urls
            }
        },
        methods: {}
    }
</script>

<style scoped>
    .coordinate-image-view {
        width: 100%;
    }

    .coordinate-select {
        margin-top: 10px;
    }

    .coordinate-select >>> .el-input__inner {
        background-color: #757575;
        border-radius: 10px;
        border: unset;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 1.04px;
        text-align: center;
        height: 24px;
    }
</style>
