<template>
    <div class="images-view">
        <div v-for="picture in pictures" :key="picture.coordinate" class="image-view-cell">
            <CoordinateImageView :picture="picture"></CoordinateImageView>
        </div>
    </div>
</template>

<script>
    import CoordinateImageView from "./CoordinateImageView";
    export default {
        name: "MedicalImageView",
        components: {CoordinateImageView},
        props:{
            pictures:{
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>
    .images-view{
        display: flex;
        flex-direction: row;
        height: 100%;
    }
    .image-view-cell {
        width: 33%;
        background-color: black;
        margin-right: 4px;
    }
</style>
