<template>
    <div class="multi-main">
        <div class="multi-main-l">{{label}}</div>
        <div class="multi-main-v">
            <el-checkbox-group v-model="val">
                <el-checkbox v-for="item in options" :key="item" :label="item"></el-checkbox>
            </el-checkbox-group>
        </div>
    </div>
</template>

<script>
    export default {
        name: "XyMultiSelect",
        props: {
            label: {
                type: String,
                required: true
            },
            value: {
                required: false,
                default: []
            },
            options: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                val: this.value?this.value:[],
            }
        },
        watch: {
            value(val) {
                this.val = val;
            },
            val(val) {
                this.$emit('update:value', val)
            }
        }
    }
</script>

<style scoped>
    .multi-main {
        background: #757575;
        border-radius: 4px;
    }

    .multi-main-l{
        height: 30px;
        line-height: 30px;
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.89px;
        text-align: left;
        padding: 0 7px;
    }

    .multi-main-v {
        display: flex;
        flex-direction: row;
        padding: 7px;
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.89px;
        text-align: left;
    }
    .multi-main-v >>> .el-checkbox__label {
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.89px;
        text-align: center;
    }
    .multi-main-v >>> .el-checkbox-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        row-gap: 10px;
    }
</style>
